@import "./variables.scss";
@import "./breakpoints.scss";

// @media (min-width: 1200px) {
//   .container {
//     width: 1440px;
//   }
// }

.d-flex {
  display: flex;
  @include bp-tablet-sm {
    display: block;
  }
}

.container {
  width: 100%;
  max-width: 1170px;
  @media (max-width: 1200px) {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .col-lg-9 {
    width: 100%;
  }
}

label {
  font-size: 16px;
}

select {
  border-radius: 50px;
  border-color: $yellow !important;
  padding: 10px !important;
  color: $primary-red;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

option {
  font-size: 16px;
}

p {
  font-size: 18px;
  @include bp-tablet-sm {
    font-size: 16px;
    line-height: 1.4;
  }
}

h2 {
  font-weight: bold;
  font-size: 42px;
  @include bp-tablet-sm {
    font-size: 30px !important;
  }
}

h2,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h2 span {
  background: linear-gradient(0deg, $yellow 30%, transparent 0);
}

h3 {
  font-weight: normal;
}

.card {
  background: $white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button,
.btn {
  font-weight: bold !important;
  font-size: 18px;
  color: $grey;
  border: none;
  border-radius: 50px !important;
  padding-bottom: 0;
  line-height: normal;
  padding: 10px 20px 10px 20px;
  &:hover {
    background: $primary-red !important;
    color: $white !important;
  }
  @include bp-tablet-sm {
    width: 100%;
  }
  span {
    font-weight: bold;
  }
}

.product-dropdown {
  color: $white !important;
  &:hover {
    background: none !important;
  }
}

.phone {
  font-size: 80px;
  font-weight: bold;
  text-decoration: underline;
  @include bp-tablet-sm {
    font-size: 40px;
  }
}

.hours {
  margin-top: 30px;
  @include bp-tablet-sm {
    margin-top: 10px;
  }
}

// Footer
footer {
  padding: 120px 0px 120px 0px;
  @include bp-tablet-sm {
    padding: 40px 0px 40px 0px;
  }
  h2,
  h3,
  p,
  a {
    color: $white;
  }
  h3 {
    font-size: 40px;
    font-weight: bold;
    @include bp-tablet-sm {
      font-size: 22px;
      margin-top: 10px;
    }
  }
  span {
    color: $yellow;
    @include bp-tablet-sm {
      line-height: 1.4;
    }
  }
  a {
    &:hover {
      color: $yellow;
    }
  }
}

.grey-bg {
  background: $light-grey;
}

#current-avail {
  border-radius: 5px 5px 0px 0px;
}

.loading {
  color: $grey;
}

// Find Out More

.find-out-more {
  padding: 80px 0px 80px 0px;
  input {
    border: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px !important;
  }
  @include bp-tablet-sm {
    padding: 20px 0px 20px 0px;
  }
}

// Formspree

.formspree-form {
  select {
    border-radius: 5px;
  }
}
