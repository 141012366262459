@import "../css/variables.scss";
@import "../css/breakpoints.scss";

body.bfetheme {
  // General
  ul {
    list-style-type: none;
  }
  button,
  .btn {
    transition: 0.2s;
    border-radius: 0;
    // background: $yellow;
    color: $grey;
    &:hover {
      text-decoration: none;
    }
    &.cart {
      border: none;
      color: #fff;
      font-size: inherit;
      padding: 0;
      background: none;
      font-weight: inherit;
      &:hover {
        color: $yellow !important;
        background: none !important;
      }
    }
  }
  // Nav
  #prenav {
    background-color: rgba($primary-red, 0.4);
    position: relative;
  }
  @media screen and (min-width: 992px) {
    .nav > li > button {
      color: #fff;
    }
  }
  @media screen and (max-width: 992px) {
    .nav-container {
      background-color: $primary-red;
    }
    .langswitcher {
      border-bottom: 1px solid $white;
    }
  }
  @media screen and (min-width: 992px) {
    .h-menu .navbar-right li ul.dropdown-menu li > a {
      transition: 0.2s;
      &:hover {
        background: $yellow;
        color: $grey;
      }
    }
  }
  // Home
  &.pgHome {
    input {
    }
    .fa-plus {
      color: $grey;
    }
    p {
      &.morefilterslink {
        transition: 0.2s;
        background: $yellow;
        color: $grey;
      }
    }
    .searchFoundText {
      span {
        color: $white;
        @media screen and (max-width: 992px) {
          color: $grey;
        }
      }
    }
    #home-introsectionwrap {
      background: url(../images/rfse-hero.jpg);
      .find-btn {
        border-radius: 0;
        border-bottom: none;
        &:hover {
          border-bottom: none;
        }
      }
    }
    #home-introsectionwrap .wehaveitall,
    #sectionFamilyList {
      background-color: $primary-red;
      color: $white;
      &::after {
        border-top-color: $primary-red;
      }
    }
    #sectionFamilyList {
      background-color: $grey;

      .homeequiplink {
        text-align: center;
      }

      @media screen and (min-width: 992px) {
        margin-top: -103px;
      }
    }
    #ironclad-section,
    #sectionFamilyList,
    .small-equip {
      h3 {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 992px) {
        padding: 40px 0px 40px 0px;
      }
      @media screen and (min-width: 992px) {
        padding: 120px 0px 120px 0px;
      }
    }
  }

  .small-equip {
    button {
      background: $yellow;
      @include bp-tablet-sm {
        font-size: inherit;
      }
    }
  }

  // Page Listing / Page Detail
  &.pgListing,
  &.pgDetail {
    // Hide horizontal scroll bar on Google Form
    iframe {
      overflow: hidden;
      height: 1630px;
      border: none;
    }
  }
  // Page Listing
  &.pgListing {
    .morefilterslink {
      border-color: $yellow;
    }
    p {
      &.op-hours {
        font-size: 11px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    .btn.top-phone {
      font-size: 25px;
      font-weight: 800;
      color: #af0e0e;
      &:hover {
        color: #333 !important;
        background-color: #e6e6e6 !important;
        border: 1px solid #adadad !important;
        box-shadow: none;
      }
    }

    .product-keyword,
    .input-group-addon {
      border-radius: 0;
    }
    #list-sorter {
      label {
        padding-right: 5px;
      }
    }
    .product-price {
      p {
        color: black;
        font-weight: 800 !important;
        font-size: 20px !important;
      }
    }
    #mosaic {
      transition: 0.2s;
      .product-wrapper {
        transition: 0.2s;
        &:hover {
          transition: 0.2s;
          background-color: $white;
          // transform: translate3D(0, -5px, 0);
          box-shadow: 0 10px 20px #c7c7c7;
        }
      }
    }
  }
  // Page Detail
  &.pgDetail {
    p {
      &.op-hours {
        font-size: 11px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    .current-avail {
      p {
        &#current-avail {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
    // .detailMainInfo {
    //   a {
    //     color: $primary-red;
    //   }
    // }
  }

  .btn-secondary {
    background: none;
    border: 1px solid $yellow;
    &:hover {
      background: $grey !important;
    }
  }

  // Footer
  footer {
    background: $primary-red;
  }

  // Formspree Form
  .formspree-form {
    input,
    select {
      margin-bottom: 20px;
    }
    button {
      background-color: $grey;
      color: $white;
      border: none;
      font-weight: 800;
      &:focus,
      &:hover {
        background-color: $grey;
        color: $white;
      }
    }
  }
  .find-out-more,
  .detailsection_contact {
    background: $yellow;
  }
  .find-out-more {
    span {
      font-weight: bold;
      background: linear-gradient(
        0deg,
        rgba(175, 14, 14, 0.5) 30%,
        transparent 0
      );
    }
  }
}
