// Breakpoints
// 1200px
@mixin bp-desktop {
  @media only screen and (max-width: 75em) {
    @content;
  }
}

// 1024px
@mixin bp-tablet {
  @media only screen and (max-width: 64em) {
    @content;
  }
}

// 992px
@mixin bp-tablet-sm {
  @media only screen and (max-width: 62em) {
    @content;
  }
}

// 768px
@mixin bp-mobile {
  @media only screen and (max-width: 48em) {
    @content;
  }
}

// 576px
@mixin bp-mobile-sm {
  @media only screen and (max-width: 36em) {
    @content;
  }
}
