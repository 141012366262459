// Variables

// General
$white: #fff;
$light-grey: #f5f5f5;

// BFE
$primary-red: #af0e0e;
$yellow: #ffc821;
$green: green;
$light-green: #208320;
$grey: #363636;
$med-grey: #505050;

// JOBSITE
$js-green: #c0d93e;
$js-hover-green: #d7f345;
$js-dark-green: #83971b;
$js-grey: #f1f1f1;

// TCAT
